import { Button } from '@flixbus/honeycomb-react';
import * as FileSaver from 'file-saver';
import * as React from 'react';

import useCollection from '../../../hooks/useCollection';

function CollectionDownloadButton(props) {
  const fetchedCollection = useCollection(props.link.split('/').pop());

  const downloadCollection = () => {
    if (fetchedCollection?.collection?.info?._postman_id !== undefined) {
      const blob = new Blob([JSON.stringify(fetchedCollection, null, 2)], {
        type: 'application/json',
      });
      FileSaver.saveAs(blob, 'collection.json');
    } else {
      console.error('Failed to download collection: Collection data is not available');
    }
  };

  return <Button onClick={() => downloadCollection()}>Download collection</Button>;
}

export default CollectionDownloadButton;
