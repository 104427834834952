import {
  Breadcrumbs,
  BreadcrumbsItem,
  Divider,
  Grid,
  GridCol,
  Heading,
  NavItem,
  NavSide,
  PageContainer,
  Text,
  alignmentHelpers,
  spaceHelpers,
} from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { NavLink, Redirect, Link as RouterLink, useLocation } from 'react-router-dom';

import useNewsService from '../../hooks/useNewsService';
import htmlRenderer from '../../utils/htmlRenderer';
import { slugify } from '../../utils/httpUtils';
import './News.css';

const space = spaceHelpers();

function NewsList() {
  const { getAllNews } = useNewsService();

  const config = {
    queryKey: ['news'],
    queryFn: () => getAllNews(),
    staleTime: 1000 * 60 * 15,
    cacheTime: 1000 * 60 * 15,
  };

  const { data: response, error, isError, isLoading } = useQuery(config);

  const location = useLocation();
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    if (response?.data?.length) {
      const currentPath = location.pathname.split('/news/')[1];
      const currentNews = response.data.find((news) => `${news.date}-${slugify(news.title)}` === currentPath);
      setSelectedNews(currentNews || response.data[0]);
    }
  }, [location.pathname, response]);

  if (isLoading) {
    return <div>Loading the News...</div>;
  }

  if (isError) {
    return (
      <div>
        <Text>News could not be loaded: {error.message}</Text>
      </div>
    );
  }

  // Redirect from `/news` to the first news item
  if (location.pathname === '/news' && response?.data?.length) {
    const firstNews = response.data[0];
    const firstNewsSlug = `${firstNews.date}-${slugify(firstNews.title)}`;
    return <Redirect to={`/news/${firstNewsSlug}`} />;
  }

  return (
    <section className="news-page">
      <Grid extraClasses="news-grid" align="top">
        <GridCol key="news-list" size={2} extraClasses="news-secondary-nav">
          <Heading size={3}>All the news</Heading>
          <NavSide aria-label="News list">
            {response?.data?.map((news, index) => {
              const newsSlug = `${news.date}-${slugify(news.title)}`;
              return (
                <NavItem key={index} to={`/news/${newsSlug}`} RouterLink={NavLink}>
                  {news.date}: {news.title}
                </NavItem>
              );
            })}
          </NavSide>
        </GridCol>
        <GridCol size={10} extraClasses={`${alignmentHelpers().textLeft}, news-content`}>
          <Breadcrumbs aria-label="breadcrumbs" extraClasses={space['2'].bottom}>
            <BreadcrumbsItem RouterLink={RouterLink} to="/">
              Home
            </BreadcrumbsItem>
            <BreadcrumbsItem RouterLink={RouterLink} to="/news">
              News
            </BreadcrumbsItem>
          </Breadcrumbs>
          {selectedNews ? (
            <div>
              <Heading size={1} extraClasses={space['1'].bottom}>
                {selectedNews.date}: {selectedNews.title}
              </Heading>
              <Divider />
              <PageContainer className="news-message">
                <Text Elem="div" extraClasses={space['4'].top}>
                  {htmlRenderer(selectedNews.message)}
                </Text>
              </PageContainer>
            </div>
          ) : (
            <Text>Currently there are no available news.</Text>
          )}
        </GridCol>
      </Grid>
    </section>
  );
}

export default NewsList;
