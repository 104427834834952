import { useQuery } from '@tanstack/react-query';

import useApiService from './useApiService';

export default function useGlobalHelpLink() {
  const { getApi } = useApiService();
  const defaultHelpLink = 'https://flixtech.atlassian.net/servicedesk/customer/portal/124';

  const config = {
    queryKey: ['global-helplink'],
    queryFn: () => getApi('meta-api'),
    staleTime: 1000 * 60 * 60,
  };

  const { data: response, error, isError, isLoading } = useQuery(config);

  if (isLoading || !response) {
    return defaultHelpLink;
  }

  if (isError) {
    if (error.message.includes('403')) {
      return defaultHelpLink;
    }
  }

  const helpdeskContact = response.data.providers?.[0]?.contact?.helpdesk;
  return helpdeskContact || defaultHelpLink;
}
